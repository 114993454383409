<template>
    <div>
        <h2>Music:90 Hard Dance</h2>

        <h3>Music Bio</h3>
        <p>I love music. And no not just stereotype music. I can name more genres that i love then i hate. Really! I
            dont
            think there is any type of music that i dont like. It all really depends on the artist and the message! Pop,
            rap, jazz, dance, rock, trance, classic, country i seriouslu love it all! But its no secret that I had my
            peak
            music experience in the late 90'es and was priviliged to experience<br><br><br>


            For a good 30 years i hated rap music.. but then therapy eminem tom mac donald.
            <br><br>
            WHen i was young, it was the other way around. I respect all genres of music and understands why they
            matters.
            However being born in in 1986 i had the joy of experincing the climaks of electronic dance music. In
            particular
            during the late 90'ies. The peak moment when Infected Mushroom, DNA & Astral Projection (the name aZtraL
            EnForceR was inspired partly by these legends. I surrender to => Aztral Projection - Nilaya) changed the
            course of psychedelic trance music, and for a century almost became mainstream, as the genre evoled into 
            the greatest happiest music genre, ever created. That, (and thank you God!) is the genre references as no other than FULLON!
            <br>
            .. I devle back into memories.. I hear a voice.. The voice of the people. United amoung syntesized music. funky beats, and dirty, dirty, DIRTY, basslines!
            <br>I hear a voice..<br>

            whereever you are in the world. You are my friend. We are united. We share this experience of spiritual enlightment and self creation. We feel our egos disolve as we
             emerge into our true beings that in words only can be described as a synergy amoungs our innersmost core of humananism, the core of the self, the possibly God given soul, 
             that gave us the choice to accept the wonders of life or to live in fear. The soul my friend. Connecting to the soul sets us free.<br>
              United amoung healing vibrations that coming out of human made  mechanical machines... Let your ego to. Become one with Love. Become one with MOVING! You were made to MOVE!
              <br>My soul is deeply connected to psychedelic fullon trance and other fast moving music genres. It is a drug without side effects that only gives more energy then it consumes!<br>
              (the first fusion reactor was build by a guy like me, in a threadmill, listing to the DJ rock the grooves of dance music!)
            <br><br>
            "While the world falls into complete chaos. A peaceful dance movement has emerged from the nation of Israel."<br>
            Enthusiasts gathers from all over the world, in large gatherings, 
            united in love and with the complete exclusion of voilence and hatred from the community. You will never believe this statement to be true, if you have not yourself, experienced it. But dont take my words for it.
            This is what everyone says that dared to surrender to the music.    
        
            <br><br>
            (I dont not take a 1:1 side in the Izrael - Palestine debat. It's too complicated)
            <br><br>
            Before this genre of music peaked though, we had a mix of pop,dance,trance,house and disco music era. You
            remember Aqua,toybox, Tiggy, Aquagen etc...
            <br>
            Let me take you back to that time .. This as of today, this has been the climax of my existance. Music changed my life forever and to the better. I will forever be grateful 
        </p>
        <div
            style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;">
            <a href="https://soundcloud.com/aztralenforcer" title="aZtraL EnForceR" target="_blank"
                style="color: #cccccc; text-decoration: none;">aZtraL EnForceR</a> · <a
                href="https://soundcloud.com/aztralenforcer/lick-on-dem-shjouus-wtf-lume-1-outtro"
                title="Lick-on-dem shjouus, wtf-lume. 1 (Outtro)" target="_blank"
                style="color: #cccccc; text-decoration: none;">Lick-on-dem shjouus, wtf-lume. 1 (Outtro)</a>
        </div>

        <iframe width="100%" height="300" scrolling="no" frameborder="no" :allow="autoplay ? 'autoplay' : ''"
            :src="getSoundCloudUrl('1267849699')">
        </iframe>




        <div
            style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;">
            <a href="https://soundcloud.com/aztralenforcer" title="aZtraL EnForceR" target="_blank"
                style="color: #cccccc; text-decoration: none;">aZtraL EnForceR</a> · <a
                href="https://soundcloud.com/aztralenforcer/liquor-talk-buffalo-shoes-vol-1"
                title="Liquor Talk &amp; Buffalo Shoes Vol. 1" target="_blank"
                style="color: #cccccc; text-decoration: none;">Liquor Talk &amp; Buffalo Shoes Vol. 1</a>
        </div>

        <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay"
            :src="getSoundCloudUrl('1222649512')">


        </iframe>



    </div>
</template>
<script>


export default {

    data: () => ({
        name: "MusicView",
        autoplay: false,
        loop: true,
        videos: [
            "ZH6tTBRB9g4",
            "Zjummn3ap6Y",
            "WJMXmiBZnPk",
            "dnGkH2ypFLE"
        ],
    }),
    mounted() {
        // Show scrollbar
        let elHtml = document.getElementsByTagName('html')[0];
        elHtml.style.overflowY = null;

        console.log(this.name, "mounted => " + (elHtml.style.overflowY === "hidden" ? "Hide Scrollbar" : "Show Scrollbar"));
    },
    methods: {
        getSoundCloudUrl(trackId) {




            // baseURL = "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/" + trackId + "&color=%2300d0ff&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true";

            const baseURL = "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/";
            const autoplayParam = this.autoplay ? "&auto_play=true" : "&auto_play=false";

            return (baseURL + trackId + "&color=%2300d0ff" + autoplayParam + "&hide_related=true&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true");

        }



    }



}
</script>