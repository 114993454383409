<template>
    <div>
        


        <div>
            <div v-if="!expanded" class="limited-text" ref="limitedText">{{ limitedText }}</div>
            <div v-else class="expanded-text" v-html="largeText"></div>
            <button v-if="!expanded" @click="expandText">Show more</button>
        </div>

    </div>
</template>


<script>

import lalalogger from "@/plugins/lalalogger.js"
let getlog = lalalogger.init("AboutView");




export default {

    components: {

    },

    data: () => ({
        largeText: 'Your large text goes here... Your large text goes here...Your large text goes here...Your large text goes herege text goes here... Your large text goes here...Your large text goes here...Your large text goes herege text goes here... Your large text goes here...Your large text goes here...Your large text goes herege text goes here... Your large text goes here...Your large text goes here...Your large text goes herege text goes here... Your large text goes here...Your large text goes here...Your large text goes herege text goes here... Your large text goes here...Your large text goes here...Your large text goes herege text goes here... Your large text goes here...Your large text goes here...Your large text goes herege text goes here... Your large text goes here...Your large text goes here...Your large text goes herege text goes here... Your large text goes here...Your large text goes here...Your large text goes herege text goes here... Your large text goes here...Your large text goes here...Your large text goes herege text goes here... Your large text goes here...Your large text goes here...Your large text goes herege text goes here... Your large text goes here...Your large text goes here...Your large text goes herege text goes here... Your large text goes here...Your large text goes here...Your large text goes herege text goes here... Your large text goes here...Your large text goes here...Your large text goes herege text goes here... Your large text goes here...Your large text goes here...Your large text goes herege text goes here... Your large text goes here...Your large text goes here...Your large text goes here...Your large text goes here...Your large text goes here...Your large text goes here...Your large text goes here...Your large text goes here...',
        expanded: false,
        maxLines: 3,

        colors: ['#FF0000', '#FF7F00', '#FFFF00', '#00FF00', '#0000FF', '#4B0082', '#9400D3'], // Rainbow colors
        currentIndex: 0,
        intervalId: null

    }),
    computed: {
        currentColor() {
            return this.colors[this.currentIndex];
        },
        limitedText() {
            // Split large text into lines and take the first `maxLines` lines
            return this.largeText.split('\n').slice(0, this.maxLines).join('\n');
        }
    },
    mounted() {

        // Show scrollbar
        let elHtml = document.getElementsByTagName('html')[0];
        elHtml.style.overflowY = "hidden";

        this.intervalId = setInterval(() => {
            this.currentIndex = (this.currentIndex + 1) % this.colors.length;
        }, 200); // Change color every 200 milliseconds

        getlog("mounted")(this.name, "mounted => " + (elHtml.style.overflowY === "hidden" ? "Hide Scrollbar" : "Show Scrollbar"));

    },

    
    beforeDestroy() {
        clearInterval(this.intervalId);
    },
    methods: {
        expandText() {
            this.expanded = true;
        },
        Test(id) {
            return `https://www.youtube.com/embed/${id}`;
        },

    }

};
</script>

<style>
.limited-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
}

.expanded-text {
    max-height: none;
    /* Expand the text */
    transition: max-height 0.3s ease;
    /* Add smooth transition animation */
}
</style>
