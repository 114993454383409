const faviconLink = document.createElement('link');
faviconLink.rel = 'icon';
faviconLink.href = '/favicon.ico'; // Replace with the path to your favicon

// Append the link element to the head section of the document
document.head.appendChild(faviconLink);

import Vue from 'vue'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router';

import '@fortawesome/fontawesome-free/css/all.css';


document.title ="LaLaGeek";
///////////////////////////////////////////////
//
//            ==> GLOBAL METHODS <==
//
///////////////////////////////////////////////

// Vue.prototype.$setLang = function (languageString) {


// }

// Vue.prototype.$getLang = function (languageString) {


// }



Vue.prototype.$utcToDate = function (utcString, languageString) {

  let lang;

  if (languageString === undefined) {
    lang = navigator.language;
    // lang = 'da-DK';
  }
  else {
    lang = languageString;
  }


  const d = new Date(utcString);

  if (lang === 'da-DK') {


    return String(d.getDate()).padStart(2, '0') + "-" + String(d.getMonth() + 1).padStart(2, '0') + "-" + d.getUTCFullYear() + " " + String(d.getHours()).padStart(2, '0') + ":" + String(d.getMinutes()).padStart(2, '0');


  }
  else {
    const ampm = d.getHours() >= 12 ? 'pm' : 'am';

    let hours = d.getHours();

    hours > 12 ? hours : (12 - hours);

    return d.getUTCFullYear() + "-" + String(d.getMonth() + 1).padStart(2, '0') + "-" + String(d.getDate()).padStart(2, '0') + " " + hours + ":" + String(d.getMinutes()).padStart(2, '0') + " " + ampm;

  }
};
///////////////////////////////////////////////
//
//            ==> COMPONENTS <==
//
///////////////////////////////////////////////
Vue.use(VueRouter);

Vue.config.productionTip = false

//BASE COMPONENTS
import BaseInput from './components/Base/BaseInput.vue'
import BaseBtn from './components/Base/BaseBtn.vue'
import BaseHoldBtn from './components/Base/BaseHoldBtn.vue'

import ShowMoreText from './components/ShowMoreText.vue'
import EmptyTemplate from './components/EmptyTemplate.vue'
// import ShowMoreText2 from './components/ShowMoreText2.vue'

const components = {

  BaseInput,
  BaseBtn,
  BaseHoldBtn,
  ShowMoreText,
  EmptyTemplate,

}

Object.entries(components).forEach(([name, component]) => Vue.component(name, component))

import HomeView from './view/HomeView.vue';
import CalendarSchemaView from './view/CalendarSchemaView.vue';
import aZtralEnForceR from './view/aZtralEnForceR.vue';
import ParadiseFeed from './view/ParadiseFeed.vue';
import MusicView from './view/MusicView.vue';
import AboutView from './view/AboutView.vue';
import InfoView from './view/InfoView.vue';
import TestView from './view/TestView.vue';
import ErrorView from './view/ErrorView.vue';


var myProps={
  //  default: true,
  lang: "da-DK",

}
// Define your routes
const routes = [

  { name: "Home", path: '/', component: HomeView, props: myProps,break:true },
  { name: "Calendar Schema", path: '/CalendarSchemaView', component: CalendarSchemaView, props: myProps,break:true },
  { name: "aZtraL*EnForceR", path: '/aZtralEnForceR', component: aZtralEnForceR, props: myProps },
  { name: "Paradise Feed", path: '/ParadiseFeed', component: ParadiseFeed, props: myProps,break:true },
  { name: "Music Omg View", path: '/MusicView', component: MusicView, props: myProps,break:true },
  { name: "Info View", path: '/InfoView' , component: InfoView, props: myProps },
  { name: "About Moi & CV", path: '/AboutView', component: AboutView, props: myProps,break:true },
  { name: "Error 3rror (404) CRASH soemthign'", path: '/ErrorView', component: ErrorView, props: myProps },

  { name: "TestView", path: '/TestView', component: TestView, props: myProps },

];

// Create a new VueRouter instance
const router = new VueRouter({
  mode: 'history', // Use history mode or hash mode
  routes
});



new Vue({
  vuetify,
  router,
  // store,
  // jslib,

  render: h => h(App)
}).$mount('#app');